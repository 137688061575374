export default {
	login({ commit }, payload) {
		commit('UPDATE_USER', payload)
	},
	tryAutoLogin({ commit }) {
		let updateUser = {
			id: 		localStorage.getItem('id'),
			firstName: 	localStorage.getItem('firstname'),
			lastName: 	localStorage.getItem('lastname'),
			email: 		localStorage.getItem('email'),
			token: 		localStorage.getItem('token')
		}				
		commit('UPDATE_USER', updateUser)
	},
	logout({ commit }) {
		commit('LOGOUT_USER')
	}
}