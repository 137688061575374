import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import VueAxios from 'vue-axios'
import VueMoment from 'vue-moment'
import moment from 'moment'

Vue.use(VueAxios, axios)
Vue.use(VueMoment, { moment })

Vue.config.productionTip = false
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL

new Vue({
	store,
	router,
	render: h => h(App),
	mounted() {
		if (localStorage.getItem('token')){
			this.$store.dispatch('tryAutoLogin')
		}
	}
}).$mount('#app')
