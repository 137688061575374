<template>
    <div class="auth-page">
        <div class="login-form p-4" style="">
            <p class="float-right">
                <select class="form-control" v-model="lang">
                    <option value="id">ID</option>
                    <option value="en">EN</option>
                </select>
            </p>
            <div class="form-group input-group mb-0">
                <input list="suggestion" v-model="search" class="form-control py-2 border-right-0 form-custom-bg" placeholder="Ketik pencarian anda"
                    v-on:keyup.enter="
                        getSearch(), 
                        page = 1,
                        model = [],
                        peraturan = [],
                        berita = [],
                        formulir = []
                    ">
                <datalist id="suggestion">
                    <option v-for="(data, index) in suggestion" :key="index" :value="data.searchTerms"></option>
                </datalist>  

                <span class="input-group-append">
                    <div class="input-group-text form-custom-bg">
                        <a class="text-blue" @click="
                            getSearch(), 
                            page = 1,
                            model = [],
                            peraturan = [],
                            berita = [],
                            formulir = []
                        ">
                            <i class="fa fa-search"></i>
                        </a>
                    </div>
                </span> 
            </div>
            <div class="d-flex row pl-1 pr-1">
                <button class="btn btn-outline-warning mr-1 mt-1" v-for="(data, index) in popular" :key="index" @click="
                        search = data.searchTerms, 
                        getSearch(), 
                        page = 1,
                        model = [],
                        peraturan = [],
                        berita = [],
                        formulir = []
                    ">
                    {{data.searchTerms}}
                </button>
            </div>
            <div style="overflow: auto; max-height: 500px;" v-if="now_search != ''">
                <h3 class="text-center font-600 mt-2 mb-2">Hasil Pencarian “{{ now_search }}”</h3>
                
                <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="semua-tab" data-toggle="tab" href="#semua" role="tab" aria-controls="semua" aria-selected="true">Semua</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="peraturan-tab" data-toggle="tab" href="#peraturan" role="tab" aria-controls="peraturan" aria-selected="false">Peraturan</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="formulir-tab" data-toggle="tab" href="#formulir" role="tab" aria-controls="formulir" aria-selected="false">Formulir</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="berita-tab" data-toggle="tab" href="#berita" role="tab" aria-controls="berita" aria-selected="false">Berita</a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="semua" role="tabpanel" aria-labelledby="semua-tab">
                        <div v-if="model.length > 0">
                            <div class="col" v-for="(data, index) in model" :key="index">
                                <div class="row">
                                    <div class="col-3">
                                        <img :src="data.img" alt="" class="img-fluid" style="cursor: pointer;" @click="lihat(data.img)">
                                    </div>
                                    <div class="col">
                                        <a class="mb-0 f-10 text-black" :href="'https://www.bpjsketenagakerjaan.go.id/' + data.pageLink" target="_blank"><small>https://www.bpjsketenagakerjaan.go.id/{{data.pageLink}}</small></a><br>
                                        <a class="mb-0 font-bold text-primary f-16" :href="'https://www.bpjsketenagakerjaan.go.id/' + data.pageLink" target="_blank">{{clear(data.title)}}</a>
                                        <p class="mb-0 f-14 text-justify"><small>{{ data.description.length > 200 ? data.description.substring(0, 200) + '...' : data.description}}</small></p>
                                        <p class="mb-0"><small><b>{{ data.scrape_date | moment('dddd, LL') }}</b></small></p>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <p class="text-primary text-center" style="cursor: pointer;" @click="page += 1" v-if="page < totalPage">Muat lebih banyak <span class="fa fa-arrow-down"></span></p>                            
                        </div>
                        <div v-else>
                            <p class="text-center">Data Tidak Ada</p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="peraturan" role="tabpanel" aria-labelledby="peraturan-tab">
                        <div v-if="peraturan.length > 0">
                            <div class="col" v-for="(data, index) in peraturan" :key="index">
                                <div class="row">
                                    <div class="col-3">
                                        <img :src="data.img" alt="" class="img-fluid" style="cursor: pointer;" @click="lihat(data.img)">
                                    </div>
                                    <div class="col">
                                        <a class="mb-0 f-10 text-black" :href="'https://www.bpjsketenagakerjaan.go.id/' + data.pageLink" target="_blank"><small>https://www.bpjsketenagakerjaan.go.id/{{data.pageLink}}</small></a><br>
                                        <a class="mb-0 font-bold text-primary f-16" :href="'https://www.bpjsketenagakerjaan.go.id/' + data.pageLink" target="_blank">{{clear(data.title)}}</a>
                                        <p class="mb-0 f-14 text-justify"><small>{{ data.description.length > 200 ? data.description.substring(0, 200) + '...' : data.description}}</small></p>
                                        <p class="mb-0"><small><b>{{ data.scrape_date | moment('dddd, LL') }}</b></small></p>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center">Data Tidak Ada</p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="formulir" role="tabpanel" aria-labelledby="formulir-tab">
                        <div v-if="formulir.length > 0">
                            <div class="col" v-for="(data, index) in formulir" :key="index">
                                <div class="row">
                                    <div class="col-3">
                                        <img :src="data.img" alt="" class="img-fluid" style="cursor: pointer;" @click="lihat(data.img)">
                                    </div>
                                    <div class="col">
                                        <a class="mb-0 f-10 text-black" :href="url + data.pageLink" target="_blank"><small>{{url}}{{data.pageLink}}</small></a><br>
                                        <a class="mb-0 font-bold text-primary f-16" :href="url + data.pageLink" target="_blank">{{clear(data.title)}}</a>
                                        <p class="mb-0 f-14 text-justify"><small>{{ data.description.length > 200 ? data.description.substring(0, 200) + '...' : data.description}}</small></p>
                                        <p class="mb-0"><small><b>{{ data.scrape_date | moment('dddd, LL') }}</b></small></p>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <p class="text-primary text-center" style="cursor: pointer;" @click="page += 1" v-if="page < totalPage">Muat lebih banyak <span class="fa fa-arrow-down"></span></p>
                        </div>
                        <div v-else>
                            <p class="text-center">Data Tidak Ada</p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="berita" role="tabpanel" aria-labelledby="berita-tab">
                        <div v-if="berita.length > 0">
                            <div class="col" v-for="(data, index) in berita" :key="index">
                                <div class="row">
                                    <div class="col-3">
                                        <img :src="data.img" alt="" class="img-fluid" style="cursor: pointer;" @click="lihat(data.img)">
                                    </div>
                                    <div class="col">
                                        <a class="mb-0 f-10 text-black" :href="url + data.pageLink" target="_blank"><small>{{url}}{{data.pageLink}}</small></a><br>
                                        <a class="mb-0 font-bold text-primary f-16" :href="url + data.pageLink" target="_blank">{{clear(data.title)}}</a>
                                        <p class="mb-0 f-14 text-justify"><small>{{ data.description.length > 200 ? data.description.substring(0, 200) + '...' : data.description}}</small></p>
                                        <p class="mb-0"><small><b>{{ data.scrape_date | moment('dddd, LL') }}</b></small></p>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <p class="text-primary text-center" style="cursor: pointer;" @click="page += 1" v-if="page < totalPage">Muat lebih banyak <span class="fa fa-arrow-down"></span></p>
                        </div>
                        <div v-else>
                            <p class="text-center">Data Tidak Ada</p>
                        </div>
                    </div>
                </div>

                <p v-if="min_score > 0 && page == totalPage">
                    Kami mengabaikan beberapa entry yang relevansinya rendah.
                    Jika mau, Anda dapat <a @click="min_score = 0, getSearch()">mengulangi dengan menyertakan hasil yang diabaikan.</a>
                </p>
            </div>
            
            <p class="text-center mt-3 mb-0">Copyright &copy; 2021 <a class="text-bold-800 grey darken-2" href="#" target="_blank">BPJS Ketenagakerjaan, Web Search Administrator</a></p> 
        </div> 
        <img src="/assets/img/footer-super-graf.png" alt="" style="position:fixed; right: 0; bottom: 0;">
    </div> 
</template>

<script>
export default {
    data() {
        return {
            search: '',
            now_search: '',

            model: [],
            peraturan: [],
            formulir: [],
            berita: [],
            page: 1,
            totalPage: 1,
            popular: [],
            suggestion: [],
            min_score: true,
            lang: 'id',
            url: ''
        }
    },
	methods: {
        getPopular() {
            let _ = this
            _.axios.get('/keyword/popular' + '?lang=' + _.lang)
                .then(resp => {
                    _.popular = resp.data.data
                })
                .catch((err) => {
                    console.log(err)
                })
		},
        getSuggestion() {
            let _ = this
            _.axios.get('/keyword/suggest' + '?lang=' + _.lang)
                .then(resp => {
                    _.suggestion = resp.data.data
                })
                .catch((err) => {
                    console.log(err)
                })
		},
		getSearch() {
            let _ = this
            _.axios.get('/search?page='+ _.page +'&q=' + _.search.split(' ').join('+') + '&lang=' + _.lang) 
            .then(resp => {
                _.now_search = _.search
                _.totalPage = resp.data.data.totalPage

                resp.data.data.data.forEach(elm => {
                    if (elm.score > _.min_score){
                        _.model.push(elm)
                        if (elm.category == 'peraturan') {
                            _.peraturan.push(elm)
                        } else if (elm.category == 'formulir') {
                            _.formulir.push(elm)
                        } else if (elm.category == 'berita') {
                            _.berita.push(elm)
                        }
                    }
                });
            })
            .catch((err) => {
                console.log(err)
            })
		},
        clear(x) {
            return x.trim().split(' ').map((word) => { 
                return word[0].toUpperCase() + word.substring(1); 
            }).join(" ");
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        }
	},
    watch: {
        page: function(){
            this.getSearch()
        },
        lang: function () {
            let _ = this
            _.getPopular()
            _.getSuggestion()
            
            _.getSearch()
            _.page = 1
            _.model = []
            _.peraturan = []
            _.berita = []
            _.formulir = []
        }
    },
    mounted() {
        this.$moment.locale('id')
        this.getPopular()
        this.getSuggestion()

        this.url = process.env.VUE_APP_SEARCH_URL
    }
}
</script>