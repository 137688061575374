export default {
	increment(state) {
      	state.count++
    },
    UPDATE_USER(state, payload) {
    	state.user = payload

    	localStorage.setItem('id', payload.id)
    	localStorage.setItem('firstname', payload.firstName)
		localStorage.setItem('lastname', payload.lastName)
		localStorage.setItem('email', payload.email)
		localStorage.setItem('token', payload.token)
    },
    LOGOUT_USER(state) {
    	state.user = null
		   	
    	localStorage.removeItem('id')
		localStorage.removeItem('firstname')
		localStorage.removeItem('lastname')
		localStorage.removeItem('email')
		localStorage.removeItem('token')
    }
}