import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Search from '../views/Search.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/search',
                name: 'Search',
                component: Search,
                meta: {
                    isGuest: true
                }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    } else if ( !store.state.token && !localStorage.getItem('token') ) {
        next({name: 'Search'})
    } else {
        next()
    }
})

export default router
